import Vue from 'vue'
import Vuex from 'vuex'

import account from '@topgeschenken/webshop-front/store/account'
import credentials from '@topgeschenken/webshop-front/store/account/credentials'
import addresses from '@topgeschenken/webshop-front/store/addresses'
import companies from '@topgeschenken/webshop-front/store/companies'
import companyUsers from '@topgeschenken/webshop-front/store/companies/users'
import countries from '@topgeschenken/webshop-front/store/countries'
import country from '@topgeschenken/webshop-front/store/geography/country'
import customers from '@topgeschenken/webshop-front/store/customers'
import giftcard from '@topgeschenken/webshop-front/store/giftcard'
import googleMap from '@topgeschenken/webshop-front/store/googleMap'
import orderCartPaymentMethod from '@topgeschenken/webshop-front/store/order/cart/paymentMethod'
import orderOrder from '@topgeschenken/webshop-front/store/order/order'
import orderOrderCollection from '@topgeschenken/webshop-front/store/order/orderCollection'
import reportCompanyReportCustomerHistory from '@topgeschenken/webshop-front/store/report/companyReportCustomerHistory'
import settings from '@topgeschenken/webshop-front/store/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account: {
      namespaced: true,
      modules: {
        credentials: {
          namespaced: true,
          ...credentials
        }
      },
      ...account,
    },
    addresses: {
      namespaced: true,
      ...addresses
    },
    companies: {
      namespaced: true,
      modules: {
        users: {
          namespaced: true,
          ...companyUsers
        }
      },
      ...companies
    },
    countries: {
      namespaced: true,
      ...countries
    },
    customers: {
      namespaced: true,
      ...customers
    },
    giftcard: {
      namespaced: true,
      ...giftcard
    },
    googleMap: {
      namespaced: true,
      ...googleMap
    },
    order: {
      namespaced: true,
      modules: {
        cart: {
          namespaced: true,
          modules: {
            paymentMethod: {
              namespaced: true,
              ...orderCartPaymentMethod
            }
          },
        },
        order: {
          namespaced: true,
          ...orderOrder
        },
        orderCollection: {
          namespaced: true,
          ...orderOrderCollection
        }
      },
    },
    geography: {
      namespaced: true,
      modules: {
        country: {
          namespaced: true,
          ...country
        }
      }
    },
    report: {
      namespaced: true,
      modules: {
        companyReportCustomerHistory: {
          namespaced: true,
          ...reportCompanyReportCustomerHistory
        }
      }
    },
    settings: {
      namespaced: true,
      ...settings
    },
  }
})
