var objectPath = require("object-path");

const languages = {
  'nl_NL': require('../../../lang/messages.nl_NL.json'),
  'nl_BE': require('../../../lang/messages.nl_BE.json'),
  'fr_BE': require('../../../lang/messages.fr_BE.json'),
  'en_US': require('../../../lang/messages.en_US.json'),
};

function htmlspecialchars(text) {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

function trans(k) {
  const currentLanguage = languages[window.currentLocale] || {}
  let trans = objectPath.get(currentLanguage, k);

  // Use first key for objects
  if (typeof trans === "object" && trans.hasOwnProperty(0)) {
    trans = trans[0];
  }

  if (trans) {
    trans = htmlspecialchars(trans);
    trans.replace(/(?:\r\n|\n|\r)/g, '<br>')
    trans.replace(/[\s\t]+/g, "")

    return trans;
  }

  return k;
}

// Add to scope for debug purposes
window.languages = languages;
window.trans = trans;
