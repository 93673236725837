import { debounce } from 'lodash'

export default class HeaderBar {
    constructor () {
        this.headerBar = document.querySelector('.header-bar')
        this.cartLink = this.headerBar.querySelector('.cart-link')
        this.cartCountUrl = this.cartLink ? this.cartLink.dataset.url : false
        this.searchForms = document.querySelectorAll('.search')
        this.searchValidation = document.querySelectorAll('.search__validation')

        this.setSearchInputWidth()
        this.initResizeEvent()
        this.checkCartCount()
        this.handleFormSubmit()
        this.tweakwiseSyncSearchInputs()
        this.handleHeaderSearchIconClick()
    }
    
    handleFormSubmit() {
        this.searchForms.forEach((form) => {
            form.addEventListener('submit', (e) => {
                e.preventDefault()
                const searchInput = e.currentTarget.querySelector('.search__input')
                const inputValue = searchInput.value
                if (
                  inputValue === ''
                  || inputValue.length < 3
                  || inputValue.length > 32
                ) {
                    searchInput.focus()
                    this.displaySearchValidationMessage()

                    return
                }

                form.submit()
            })
        }, {
            passive: true
        })
    }

    handleHeaderSearchIconClick() {
        const searchIcons = [...document.querySelectorAll('.search__icon')]
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                const tweakwiseInput = document.getElementById('tweakwise-input')
                if (!tweakwiseInput) {
                    return
                }

                this.addIconListeners(searchIcons, tweakwiseInput)
            })
        })
    }

    addIconListeners(searchIcons, tweakwiseInput) {
        searchIcons.forEach((icon) => {
            icon.addEventListener('click', (e) => {
                const target = e.currentTarget
                const searchInputs = [...document.querySelectorAll('.search__input')]
                searchInputs.forEach((item) => {
                    if (target.classList.contains('search__icon--delete')) {
                        searchInputs.forEach((item) => {
                            item.value = ''
                            item.parentNode.classList.remove('search__field--filled')
                        })
                        item.dispatchEvent(new Event('input'))
                    } else {
                        target.focus()
                    }
                })

                this.tweakwiseHandleInputSearch(tweakwiseInput, target)
            })
        })
    }

    handleHeaderSearchIcons() {
        const searchInputs = [...document.querySelectorAll('.search__input')]
        searchInputs.forEach((item) => {
            const searchParentClassList = item.parentNode.classList
            this.handleHeaderSearchFill(item, searchParentClassList)
            item.addEventListener('keyup', () => {
                this.handleHeaderSearchFill(item, searchParentClassList)
            })
        })
    }

    handleHeaderSearchFill(searchInput, searchParentClassList) {
        searchInput.value !== '' ?
          searchParentClassList.add('search__field--filled') :
          searchParentClassList.remove('search__field--filled')
    }

    setSearchInputWidth () {
        const searchInput = document.querySelector('.search__input')
        const search = document.querySelector('.search')
        const searchIsShown = search && search.clientHeight > 60
        
        if (!searchInput || !searchIsShown) {
            return
        }

        if (searchIsShown) {
            searchInput.style.width = (searchInput.getAttribute('placeholder').length + 1.5) + 'ch'
            return
        }

        searchInput.removeAttribute('style')
    }

    initResizeEvent () {
        window.addEventListener('resize', debounce(() => {
            this.setSearchInputWidth()
        }, 500))
    }

    checkCartCount () {
        this.cartCountUrl && $.ajax({
            url: this.cartCountUrl,
        }).done((count) => {
            if (count > 0) {
                this.cartLink.classList.add('cart-link--active');
            }
        });
    }

    displaySearchValidationMessage () {
        [...this.searchValidation].forEach((validation) => {
            validation.classList.add('search__validation--visible')
        })
        setTimeout(() => {
            [...this.searchValidation].forEach((validation) => {
                validation.classList.remove('search__validation--visible')
            })
        }, 3000)
    }

    tweakwiseSyncSearchInputs () {
        const searchInputs = document.querySelectorAll('.search__input')
        if (searchInputs && searchInputs.length === 0) {
            return
        }
        
        document.addEventListener('DOMContentLoaded', () => {
            setTimeout(() => {
                const tweakwiseInput = document.getElementById('tweakwise-input')
                if (!tweakwiseInput) {
                    return
                }

                Array.from(searchInputs).forEach((searchInput) => {
                    this.tweakwiseHandleReloadInput(tweakwiseInput, searchInput)
                    this.tweakwiseHandleInputSearch(tweakwiseInput, searchInput)
                })
                this.handleHeaderSearchIcons()
            }, 2000)
        })
    }

    tweakwiseHandleInputSearch(tweakwiseInput, searchInput) {
        searchInput.addEventListener('input', (e) => {
            const element = e.currentTarget
            tweakwiseInput.value = element.value
            tweakwiseInput.dispatchEvent(new Event('input'))
            tweakwiseInput.dispatchEvent(new Event('keydown'))
        })
    }

    tweakwiseHandleReloadInput(tweakwiseInput, searchInput) {
        if (tweakwiseInput.value !== '') {
            searchInput.value = tweakwiseInput.value
        }
    }
}
