import { debounce } from 'lodash'

export default class MobileMenu {
  constructor () {
    this.elm = null
    this.mobileNavigation = null
    this.resizeTimeout = 1000
    this.scrollTimeout = 25
    this.scrollThreshold = 50
    this.scrollPosition = window.scrollY
    this.setup = this.setup.bind(this)
    this.onMobile = this.onMobile.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.bindEvents()
  }

  bindEvents () {
    window.addEventListener('DOMContentLoaded', this.setup)
  }
  
  setup () {
    this.elm = document.querySelector('.header-bar')
    this.mobileNavigation = document.getElementById('mobile_navigation')
    window.addEventListener('resize', debounce(this.onMobile, this.resizeTimeout))
    this.onMobile()
  }

  onMobile () {
    if (window.innerWidth >= 992) {
      return
    }

    window.addEventListener('scroll', debounce(this.onScroll, this.scrollTimeout))
  }

  onScroll () {
    if (!this.mobileNavigation.classList.contains('hidden')) {
      return
    }

    if (window.scrollY > this.elm.offsetHeight) {
      this.hideMobileHeader()
    }

    setTimeout(this.handleScroll, this.scrollTimeout)
  }

  handleScroll () {
    const newScroll = window.scrollY

    if (newScroll < this.scrollThreshold || (this.scrollPosition - newScroll > this.scrollThreshold)) {
      this.showMobileHeader()
    }

    if (newScroll - this.scrollPosition > this.scrollThreshold) {
      this.hideMobileHeader()
    }
  
    this.scrollPosition = newScroll
  }

  showMobileHeader() {
    this.elm.classList.remove('header-bar--hidden')
  }

  hideMobileHeader() {
    this.elm.classList.add('header-bar--hidden')
  }
}
