import EmailValidation from "./email-validation";

class InputfieldValidation {
    constructor() {
        let emailInputs = document.getElementsByClassName('email-validation');
        emailInputs.forEach((emailInput) => {
            new EmailValidation(emailInput, ['change', 'load']);

            emailInput.dispatchEvent(new CustomEvent('load'));
        });
    }
}

export default InputfieldValidation;
