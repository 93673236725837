import './../../assets/scss/app22.scss';

import './../js/libs/sentry'
import './includes/trans'

import Vue from 'vue';
import TgVatSwitcher from '@topgeschenken/webshop-front/components/layout/TgVatSwitcher'
import hideMessages from './includes/messages'

import Navigation from './components/Navigation';
import MobileHeader from './components/MobileHeader';
import MobileMenu from './components/MobileMenu';
import ReturnToTop from './components/ReturnToTop';
import CompanySwitcher from './components/company_switcher';
import HeaderBar from './components/header_bar'

const banner = require('./includes/banner');

import formValidation from './includes/form-validation';
import InputFieldValidation from './validation/inputfield-validation';
import { initializeVueComponent } from "../utils/vue";

let APP = new function () {
    this.banner = banner;
    this.formValidation = formValidation
    this.companySwitcher = new CompanySwitcher
    this.headerBar = new HeaderBar
    this.navigation = new Navigation
    this.mobileHeader = new MobileHeader

    initializeVueComponent('#header_vat_switcher', TgVatSwitcher).then((vueApp) => {
        if(vueApp && vueApp.$children.length > 0 && vueApp.$children[0]) {
            Vue.prototype.watchVatSwitcher = Vue.observable(vueApp.$children[0]);

            new Vue({
                watch: {
                    'watchVatSwitcher.enabled' (enabled) {
                        window.location.reload()
                    }
                }
            })
        }
    });

    this.fireLoadedEvent = function() {
        document.dispatchEvent(new Event('app:loaded'));
    }
};

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

APP.init = function () {
    if ($('#header').length === 1) {
        this._initHeader();
    }

    this._bindEvents();

    this.mobileMenu = new MobileMenu();
    this.returnToTop = new ReturnToTop();

    this.formValidation.init();

    $.fn.select2.defaults.set('language', 'nl');
};

APP._bindEvents = function () {
    // Setup lazyload
    new LazyLoad({
        elements_selector: ".lazy"
    });

    $('.footer-dropdown h3').on('click', function (e) {
        if ($(window).width() < 992) {
            $(this).parent().find('ul').toggleClass('hidden');
            $(this).find('.down').toggleClass('hidden');
            $(this).find('.up').toggleClass('hidden');
        }
    }).click();

    $('#toggle-menu').on('click', function (e) {
        $('html').toggleClass('show-mobile-menu');
    });

    $('body').on('blur', 'textarea, input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="tel"]', function () {
        $(this).removeClass('filled');

        if ($(this).val()) {
            $(this).addClass('filled');
        }
    });

    initializeVueComponent().then((vm) => {
        if (window.IS_USER_LOGGED_IN === false) {
            // Clean up gap between session and token authentication
            vm.$auth.logout() // Remove
        }

        $('.trigger-logout').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            vm.$auth.logout()

            window.location.href = e.currentTarget.href
        })
    });

    hideMessages()
};

APP._initHeader = function () {
    $('.header-blok', '#header').delay(1000).fadeIn();
};

$(function() {
    APP.init();

    APP.fireLoadedEvent();

    new InputFieldValidation();
});

window.APP = APP;
export default APP;
