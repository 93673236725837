export default class CompanySwitcher {
    constructor() {
        $('.change-current-company-trigger').on('click', (e) => {
            e.preventDefault();
            this.showCompanySwitcherModal('#header_company_switcher');
        });

        if (document.getElementById('header_company_switcher')) {
            let data = sessionStorage.getItem('selectCompanyModalIsShown');
            if (null === data) {
                this.showCompanySwitcherModal('#header_company_switcher');
            }
        }
    }

    showCompanySwitcherModal(searchInSelector){
        swal.fire({
            title: trans('account.company.switcher.set_active_company'),
            type: 'question',
            html: $(searchInSelector).find('.companySwitcherContent').html(),
            showCancelButton: true,
            cancelButtonText: trans('close.button'),
            confirmButtonText: trans('modal.save_changes'),
            reverseButtons: true,
            preConfirm: () => {
                $('#content').animate({opacity: 0.4});

                const options = $(swal.getContent()).find('.active-company-options');
                $.post(`/account/active-company-switch/${options.val()}`, () => {
                    sessionStorage.setItem('selectCompanyModalIsShown', 'true');
                    location.reload();
                })
            }
        });

    }
}
