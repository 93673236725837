export default class ReturnToTop {
    constructor() {
        this.elm = $('#return_to_top');
        this.windowElm = $(window);

        this.animationTime = 500;

        this.bindEvents();
    }

    bindEvents() {
        this.elm.on('click', this.onClick.bind(this));
        this.windowElm.scroll(this.onWindowScroll.bind(this));
    }

    /**
     * Click handler to go back to top.
     */
    onClick() {
        $('body, html').animate({scrollTop: 0}, this.animationTime);
    }

    /**
     * @param data
     */
    onWindowScroll(data) {
        const top = $(window).scrollTop();
        const show = top >= 40;

        this.toggleElm(show);
    }

    /**
     * @param show
     */
    toggleElm(show) {
        this.elm.toggleClass('show', show);
    }
}