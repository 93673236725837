import _forEach from 'lodash/forEach';
import validate from 'validate.js';

const formValidation = new function () {
    this.forms = $('form[data-client-validation]');
};

/**
 * Initialize form validation
 */
formValidation.init = function () {
    /**
     * Add a custom validate formatter to remove
     * field labels from error messages
     */
    validate.formatters.custom = (errors) => {
        return errors.map((error) => {

            const errorObject = {
                name: error.attribute,
                value: error.value,
                message: error.options.message
            };

            if (!errorObject.message) {
                errorObject.message = formValidation.guessLabel(error.attribute);

                if (errorObject.message) {
                    errorObject.message += ' is niet ingevuld.';
                }
            }

            return errorObject;
        });
    };

    validate.options = {
        format: 'custom',
        cleanAttributes: false
    };

    /**
     * Bind form validation to each form
     */
    this.forms.each(function () {
        formValidation.bindEvents(this);
    });
};

/**
 * Bind all events to the form
 *
 * @param form
 */
formValidation.bindEvents = (form) => {
    let submitButtonClicked = null;

    const submitButtons = $(form).find('[type=submit]');
    submitButtons.each(function () {
        $(this).data('original-html', $(this).html());
    })

    submitButtons.click((e) => {
        if (e.currentTarget !== 'undefined') {
            submitButtonClicked = $(e.currentTarget)
        }
    });

    $(form).on('submit', (e) => {
        if (submitButtonClicked !== null && submitButtonClicked.attr('formnovalidate') === 'formnovalidate') {
            submitButtonClicked = null;
            return;
        }

        // Disable to block multiple submits
        if (submitButtonClicked) {
            submitButtonClicked.prop('disabled', true);
            submitButtonClicked.prepend('<i style="margin-right: 10px;" class="fa fa-spinner fa-spin fa-fw"></i>');
        }

        if($(form).find('[type=submit]:focus').attr('formnovalidate') === 'formnovalidate'){
            return;
        }

        const fields = validate.collectFormValues(form);
        const constraints = formValidation.convertAttributesToConstraints(form);
        const validationResult = validate(fields, constraints);

        // Prevent form submit when errors occur
        if (validationResult && typeof(validationResult) !== 'undefined') {
            e.preventDefault();

            // Enable after error is thrown
            if (submitButtonClicked) {
                submitButtonClicked.prop('disabled', false)
                submitButtonClicked.html(submitButtonClicked.data('original-html'))
            }

            $(':input[data-clear-when-invalid]').val('');
            formValidation.renderErrors(form, validationResult);
        }

        // Enable submit after 5 seconds (fallback to submit again, if something unexpected went wrong)
        if (submitButtonClicked) {
            setTimeout(() => {
                submitButtonClicked.prop('disabled', false)
                submitButtonClicked.html(submitButtonClicked.data('original-html'))
            }, 5000)
        }
    });

    $(form).on('change', ':input.error', formValidation.checkInputChange);
};

/**
 * Convert legacy form validation attributes to work with new validate.js library
 *
 * @param form
 *
 * @returns {{}} Object collection of constraints
 */
formValidation.convertAttributesToConstraints = (form) => {
    let constraints = {};

    $(':input', form)
    .not('[data-ignored]')
    .each((key, input) => {
        const field = $(input);

        formValidation.removeErrorClasses(field.attr('name'));

        if (field.is(':hidden') && (field.next('label').length === 0 || field.next('label').is(':hidden'))) {
            return true;
        }

        let constraint = {};

        switch (field.attr('data-validation')) {
            case 'email':
                constraint.email = true;

                if (field.attr('data-validation-error-msg')) {
                    constraint.email = {
                        message: field.attr('data-validation-error-msg')
                    }
                }

                break;
            case 'alphanumeric':
                constraint.format = {
                    pattern: '[a-z0-9]+',
                    flags: 'i'
                };

                if (field.attr('data-validation-error-msg')) {
                    constraint.format.message = field.attr('data-validation-error-msg');
                }

                break;
        }

        if (field.attr('required') || field.attr('data-validation') === 'required') {
            constraint.presence = true;

            if (field.attr('minlength') && field.attr('data-validation-error-invalid-length-msg')) {
                constraint.length = {
                    message: field.attr('data-validation-error-invalid-length-msg'),
                    minimum: parseInt(field.attr('minlength')),
                }
            }

            if (field.attr('data-validation-error-msg')) {
                constraint.presence = {
                    message: field.attr('data-validation-error-msg')
                }
            }
        }

        constraints[field.attr('name')] = constraint;
    });

    return constraints;
};

/**
 * Render error container and add individual error attributes to elements
 *
 * @param form
 *
 * @param validationResult
 */
formValidation.renderErrors = (form, validationResult) => {
    const validationMessage = trans('form.invalid_message.description')
    let errorContainer = $('<div class="form-error alert alert-danger"><p>' + validationMessage + '</p><ul></ul></div>');
    let errorListItem = $('<li></li>');

    _forEach(validationResult, (error) => {
        formValidation.applyErrorClasses(error.name);

        if (error.message !== null && typeof(error.message) !== 'undefined') {
            errorContainer
            .find('ul')
            .append(
                errorListItem
                .clone()
                .attr('data-identifier', error.name)
                .html(error.message)
            );
        }
    });

    // Remove previously added error container
    $(form)
    .find('.form-error')
    .remove();

    // Add new error container to form
    $(form)
    .prepend(errorContainer);

    $('html, body').animate({
        scrollTop: 0
    }, 500);
};

/**
 * Return JQuery instance of form element by name attribute
 *
 * @param name
 *
 * @returns {jQuery|HTMLElement}
 */
formValidation.getFormElement = (name) => {
    return $(':input[name="' + name + '"]');
};

/**
 * Add error flags to proper elements
 *
 * @param name
 */
formValidation.applyErrorClasses = (name) => {
    formValidation.getFormElement(name)
    .toggleClass('error', true)
    .closest('.form-group')
    .toggleClass('has-error', true);

    return formValidation;
};

/**
 * Remove error flags to proper elements
 *
 * @param name
 */
formValidation.removeErrorClasses = (name) => {
    formValidation.getFormElement(name)
    .toggleClass('error', false)
    .closest('.form-group')
    .toggleClass('has-error', false);

    return formValidation;
};

/**
 * Remove error flags to proper elements
 *
 * @param name
 */
formValidation.removeErrorMessage = (name) => {
    const formErrors = formValidation.getFormElement(name)
    .closest('form[data-client-validation]')
    .find('.form-error');

    // Remove the corresponding message list item
    formErrors
    .find('[data-identifier="' + name + '"]')
    .remove();

    // If there are no errors left, remove the error container
    if (formErrors.find('li').length === 0) {
        formErrors.remove();
    }

    return formValidation;
};

/**
 * Guess label based on input element
 *
 * @param name
 *
 * @returns {string}
 */
formValidation.guessLabel = (name) => {
    const label = formValidation.getFormElement(name)
    .next('label');

    const radioPlaceholder = label.closest('.form-group').find('div[placeholder]:first-child');
    if(typeof(radioPlaceholder) !== 'undefined') {
        return radioPlaceholder.attr('placeholder');
    }

    if (!label) {
        return '';
    }

    const placeholder = label.attr('placeholder');

    if (typeof(placeholder) === 'undefined') {
        return label.text();
    }

    return placeholder.replace('*', '');
};

/**
 * Check the input after change event is fired
 * and if there is a value or checked item remove
 * the error classes
 *
 * @param e
 */
formValidation.checkInputChange = (e) => {
    const target = $(e.currentTarget);
    const type = target.prop('type').toLowerCase();

    let isValid = false;

    switch (type) {
        case 'radio':
        case 'checkbox':
            isValid = $('input[name="' + target.attr('name') + '"]:checked').length;

            break;
        case 'select':
        case 'select-one':
        case 'text':
        case 'email':
        case 'password':
        case 'tel':
        case 'number':
            isValid = target.val();

            break;
    }

    if (isValid) {
        formValidation
        .removeErrorClasses(target.attr('name'))
        .removeErrorMessage(target.attr('name'));
    }
};

export default formValidation;
