import Vue from 'vue'
import store from './../../store';
import './../../plugin-loader'
import { i18n } from '../../plugins/vue-i18n'

const languages = {
  'nl-NL': require('../../lang/frontends.nl_NL.json'),
  'nl-BE': require('../../lang/frontends.nl_BE.json'),
  'fr-BE': require('../../lang/frontends.fr_BE.json'),
  'en-US': require('../../lang/frontends.en_US.json'),
};

export const loadedLanguages = [];
export const loadLanguageAsync = async (lang) => {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve()
  }

  i18n.setLocaleMessage(lang, languages[lang])
  loadedLanguages.push(lang)
}

export async function initializeVueComponent(selector = null, component, props = {}, setup = {}) {
  await loadLanguageAsync(window._CORE.locale);

  if (window._CORE.locale !== 'nl-NL') {
    await loadLanguageAsync('nl-NL');
  }
  const vm = new Vue({
    store,
    i18n,
    ...setup,
    render(h) {
      return h(component, {
        props: (function () {
          let attributes = this.$el.attributes;

          if (typeof attributes['data-initial-props'] !== 'object' || typeof attributes['data-initial-props'].value !== 'string') {
            return {};
          }

          return {
            ...props,
            ...JSON.parse(attributes['data-initial-props'].value)
          };
        }.bind(this))(),
      })
    }
  });

  if( selector === null ) {
    return vm
  }

  if( selector instanceof HTMLElement || document.querySelector(selector)) {
    vm.$mount(selector)
  }

  return vm;
}
