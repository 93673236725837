export default class MobileMenu {
    constructor() {
        this.elm = document.getElementById('mobile_navigation');
        this.mobileHeaderElm = document.getElementById('mobile_header');
        this.timerElm = document.getElementById('timer');
        this.toggleMenuElm = document.querySelector('.toggle-menu');
        this.toggleSubMenuElms = document.querySelectorAll('.toggle-sub-menu');

        this.mobileNavigationElm = document.getElementById('mobile_navigation');

        this.onScroll = this.onScroll.bind(this);
        this.onToggleMenuClick = this.onToggleMenuClick.bind(this);
        this.onAnchorClick = this.onAnchorClick.bind(this);

        MobileMenu.onToggleSubMenuClick = MobileMenu.onToggleSubMenuClick.bind(this);

        this.bindEvents();
    }

    bindEvents() {
        if (!this.elm) {
            return
        }

        if (this.timerElm) {
            window.addEventListener('scroll', this.onScroll);
        } else {
            this.toggleBorder(true);
        }

        this.toggleMenuElm.addEventListener('click', this.onToggleMenuClick);

        for (let i = 0; i < this.toggleSubMenuElms.length; i++) {
            const toggleSubMenuElm = this.toggleSubMenuElms[i];
            toggleSubMenuElm.addEventListener('click', MobileMenu.onToggleSubMenuClick);
        }

        const aTagElements = this.elm.querySelectorAll('a');
        for (let i = 0; i < aTagElements.length; i++) {
            const aTagElm = aTagElements[i];
            aTagElm.addEventListener("click", this.onAnchorClick);
        }
    }

    onAnchorClick(e) {
        const targetElm = (e.target.tagName === 'A')
            ? e.target
            : e.target.closest('a');

        if (targetElm.getAttribute('href') === "#") {
            e.preventDefault();

            const iconElm = targetElm.querySelector('.toggle-sub-menu');
            iconElm.click();
        }
    }

    static onToggleSubMenuClick(e) {
        e.preventDefault();
        e.stopPropagation();

        const iconElm = e.target;
        const submenuElm = iconElm.closest('.menu-item');
        const ulElm = submenuElm.closest('ul');
        const submenuListElm = submenuElm.querySelector('.item-children');
        const submenuIconElements = ulElm.querySelectorAll('.toggle-sub-menu');
        const submenuIsActive = submenuListElm.classList.contains('active');

        MobileMenu.hideOtherSubMenus(submenuIconElements);

        submenuListElm.classList.toggle('active', !submenuIsActive);
        MobileMenu.toggleSubMenuIcon(iconElm, !submenuIsActive);
    }

    static hideOtherSubMenus(elements) {
        for (let i = 0; i < elements.length; i++) {
            const iconElm = elements[i];
            const submenuElm = iconElm.closest('.menu-item');
            const submenuListElm = submenuElm.querySelector('.item-children');

            submenuListElm.classList.remove('active');
            MobileMenu.toggleSubMenuIcon(iconElm, false);
        }
    }

    static toggleSubMenuIcon(elm, active) {
        const classIconOpen = elm.dataset.iconOpen;
        const classIconClose = elm.dataset.iconClose;

        elm.classList.toggle(classIconOpen, !active);
        elm.classList.toggle(classIconClose, active);
    }

    onToggleMenuClick() {
        this.toggleMenuElm.classList.toggle('toggle-menu-active');

        this.mobileNavigationElm.classList.toggle('hidden');
    }

    /**
     * @param data
     */
    onScroll(data) {
        const top = window.scrollY;
        const scrollTop = this.timerElm.clientHeight;
        const showBorder = (top + 5) >= scrollTop;

        this.toggleBorder(showBorder);
    }

    /**
     * @param showBorder
     */
    toggleBorder(showBorder) {
        if (this.mobileHeaderElm === null) {
            return;
        }

        const classList = this.mobileHeaderElm.classList;

        if (showBorder && !classList.contains('border')) {
            classList.add('border');
        } else if (!showBorder && classList.contains('border')) {
            classList.remove('border');
        }
    }
}
