import Vue from 'vue'
import { Store } from 'vuex'
import axios from 'axios'
import store from './store'
import router from './router'
import cookieUniversal from 'cookie-universal'

import '@topgeschenken/webshop-front/plugins/font-awesome-icon'
import '@topgeschenken/webshop-front/plugins/toast'
import '@topgeschenken/webshop-front/plugins/vue-currency'
import '@topgeschenken/webshop-front/plugins/vue'
import '@topgeschenken/webshop-front/plugins/vuelidate'

import slot from '@topgeschenken/front-library/mixins/slot'

import EnvironmentPlugin from '@topgeschenken/front-library/plugins/EnvironmentPlugin'
import StoragePlugin from '@topgeschenken/front-library/plugins/StoragePlugin'
import ApiPlugin from '@topgeschenken/front-library/plugins/ApiPlugin'
import ApiDataProvider from '@topgeschenken/front-library/plugins/ApiDataProvider'
import AuthPlugin from '@topgeschenken/front-library/plugins/AuthPlugin'
import AccountPlugin from '@topgeschenken/front-library/plugins/AccountPlugin'
import MercureListener from '@topgeschenken/front-library/plugins/MercureListener'

let context = {
  $config: {},
  $axios: axios,
  app: {
    store,
    router
  },
  route: {
    meta: {
      requireAuth: false // Tmp fix until router is implemented (account branch)
    }
  },
  redirect: (path) => {
    return router.push(path)
  },
  $cookies: cookieUniversal()
}

Store.prototype[`$axios`] = axios

const injectPlugin = function (name, callback, options = {}) {
  this[`$${name}`] = callback

  const MyPlugin = {}
  MyPlugin.install = function (Vue, options) {
    Vue[`$${name}`] = callback
    Vue.prototype[`$${name}`] = callback
    Store.prototype[`$${name}`] = callback
  }

  Vue.use(MyPlugin, options)
}.bind(context);

EnvironmentPlugin(context, injectPlugin)
StoragePlugin(context, injectPlugin)
ApiPlugin(context, injectPlugin)
ApiDataProvider(context, injectPlugin)
AuthPlugin(context, injectPlugin)
AccountPlugin(context, injectPlugin)
MercureListener(context, injectPlugin)

Vue.mixin(slot)

Vue.prototype[`localePath`] = (to) => {
  if (typeof to === "string") {
    to = {
      name: to
    }
  }

  if (to.name && !to.name.includes('___nl-nl___default')){
    to.name = to.name + '___nl-nl___default'
  }

  const match = router.matcher.match(to);
  if (match.matched.length > 0) {
    return match.path;
  }

  return to;
}

// Update VUE to handle on loggedin user-state
$( window ).on( "load", () => {
  if (context.$auth.isAuthenticated() === true) {
    context.$auth.triggerLoggedInEvent();
  }
});
