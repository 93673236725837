export default class Navigation {
    constructor() {
        this.elm = document.getElementById('navigation');

        if (this.elm) {
            this.primaryMenuItems = this.elm.querySelectorAll('.row > ul > li');
            this.secondaryMenuItemHeadings = this.elm.querySelectorAll('ul li h3');
            this.aTagElements = this.elm.querySelectorAll('a');

            this.onMouseEnter = this.onMouseEnter.bind(this);
            this.onAnchorClick = this.onAnchorClick.bind(this);

            this.bindEvents();
        }
    }

    bindEvents() {
        for (let i = 0; i < this.primaryMenuItems.length; i++) {
            const item = this.primaryMenuItems[i];

            item.addEventListener('mouseenter', this.onMouseEnter);
        }

        for (let i = 0; i < this.aTagElements.length; i++) {
            const aTagElm = this.aTagElements[i];

            aTagElm.addEventListener('click', this.onAnchorClick);
        }
    }

    onAnchorClick(e) {
        const targetElm = (event.target.tagName === 'A')
            ? event.target
            : event.target.closest('a');

        if (targetElm.getAttribute('href') === '#') {
            e.preventDefault();
        }
    }

    onMouseEnter(e) {
        const childElm = e.target.querySelector('ul.depth-2');

        if (childElm) {
            childElm.style.top = (e.target.offsetTop + this.elm.clientHeight) + 'px';
        }

        const height = this.calcHeightOfHeading();
        this.setNavItemHeight(height);

        // Hide menu items without children
        const secondMenuUls = document.querySelectorAll('#navigation ul ul');
        for (let i = 0; i < secondMenuUls.length; i++) {
            if (secondMenuUls[i].childElementCount === 0) {
                secondMenuUls[i].style.display = 'none';
            }
        }

        setTimeout(() => {
            // Load new images
            new LazyLoad({
                elements_selector: ".lazy"
            });
        })
    }

    setNavItemHeight(height) {
        this.secondaryMenuItemHeadings.forEach(function(heading) {
            heading.style.height = `${height}px`;
        });
    }

    /**
     * Retrieve pixel size of the highest heading
     *
     * @returns {number}
     */
    calcHeightOfHeading() {
        let height = 0;
        this.secondaryMenuItemHeadings.forEach(function(item) {
            item.style.removeProperty('height');

            if (item.clientHeight > height) {
                height = item.clientHeight;
            }
        });

        return height;
    }
}
