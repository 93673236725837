import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'nav__link--active',
  routes: [
    {
      path: '/account-nieuw', // @todo rename to '/account'
      component: () => import(/* webpackChunkName: "pages/account" */ '@topgeschenken/webshop-front/pages/account.vue'),
      children: [
        {
          "name": "account___nl-nl___default",
          "path": "",
          redirect: 'account-overview___nl-nl___default',
          component: () => import(/* webpackChunkName: "pages/account/index" */ '@topgeschenken/webshop-front/pages/account/index.vue')
        },
        {
          "path": "adresboek",
          component: () => import(/* webpackChunkName: "pages/account/addressbook" */ '@topgeschenken/webshop-front/pages/account/addressbook.vue'),
          "children": [
            {
              "name": "account-addressbook___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/addressbook/index" */ '@topgeschenken/webshop-front/pages/account/addressbook/index.vue')
            }
          ]
        },
        {
          "path": "bedrijf",
          component: () => import(/* webpackChunkName: "pages/account/company" */ '@topgeschenken/webshop-front/pages/account/company.vue'),
          "children": [
            {
              "name": "account-company___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/company/index" */ '@topgeschenken/webshop-front/pages/account/company/index.vue')
            }
          ]
        },
        {
          "path": "bestellingen",
          component: () => import(/* webpackChunkName: "pages/account/orders" */ '@topgeschenken/webshop-front/pages/account/orders.vue'),
          "children": [
            {
              "name": "account-orders___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/orders/index" */ '@topgeschenken/webshop-front/pages/account/orders/index.vue'),
            },
            {
              "name": "account-orders-uuid___nl-nl___default",
              "path": ":uuid",
              component: () => import(/* webpackChunkName: "pages/account/orders/_uuid" */ '@topgeschenken/webshop-front/pages/account/orders/_uuid.vue'),
            }
          ]
        },
        {
          "path": "gebruikers",
          component: () => import(/* webpackChunkName: "pages/account/users" */ "@topgeschenken/webshop-front/pages/account/users.vue"),
          "children": [
            {
              "name": "account-users___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/users/index" */ "@topgeschenken/webshop-front/pages/account/users/index.vue"),
            }
          ]
        },
        {
          "path": "instellingen",
          component: () => import(/* webpackChunkName: "pages/account/settings" */ "@topgeschenken/webshop-front/pages/account/settings.vue"),
          "children": [
            {
              "name": "account-settings___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/settings/index" */ "@topgeschenken/webshop-front/pages/account/settings/index.vue"),
            }
          ]
        },
        {
          "path": "overzicht",
          component: () => import(/* webpackChunkName: "pages/account/overview" */ "@topgeschenken/webshop-front/pages/account/overview.vue"),
          "children": [
            {
              "name": "account-overview___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/overview/index" */ "@topgeschenken/webshop-front/pages/account/overview/index.vue"),
            }
          ]
        },
        {
          "path": "rapportages",
          component: () => import(/* webpackChunkName: "pages/account/reports" */ "@topgeschenken/webshop-front/pages/account/reports.vue"),
          "children": [
            {
              "name": "account-reports___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/reports/index" */ "@topgeschenken/webshop-front/pages/account/reports/index.vue"),
            }
          ]
        },
        {
          "path": "wachtwoord-wijzigen",
          component: () => import(/* webpackChunkName: "pages/account/changePassword" */ "@topgeschenken/webshop-front/pages/account/changePassword.vue"),
          "children": [
            {
              "name": "account-changePassword___nl-nl___default",
              "path": "",
              component: () => import(/* webpackChunkName: "pages/account/changePassword/index" */ "@topgeschenken/webshop-front/pages/account/changePassword/index.vue"),
            }
          ]
        }
      ],
      "meta": {
        "name": "Account",
        "requireAuth": true,
        "nuxtI18n": {
          "paths": {
            "undefined": "/mijn-account"
          }
        }
      }
    },
    {
      path: '/account-nieuw/*', // @todo rename to '/account/*'
      redirect: { name: 'account-overview___nl-nl___default' }
    }
  ]
})

export default router
