import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: window._CORE.locale,
  fallbackLocale: 'nl-NL',
  messages: {
    'nl-NL': {},
    'nl-BE': {},
    'en-US': {},
    'fr-BE': {}
  }
})
