import { post } from 'axios';

class EmailValidation {
    constructor(inputElm, eventListeners = []) {
        if (inputElm === null) {
            return;
        }

        this.inputElm = inputElm;
        this.container = this.createContainer();

        eventListeners.forEach((element) => {
            this.bindEventListener(element);
        });

        this.inputElm.after(this.container);
    }

    createContainer() {
        let container = document.createElement('div');
        container.classList.add('email-suggestions');

        return container;
    }

    createClickAbleSuggestion() {
        let clickable = document.createElement('a');
        clickable.href = '#';

        return clickable;
    }

    bindEventListener(event) {
        this.inputElm.addEventListener(event, (ev) => {
            if (ev.target === null || ev.target.value === '') {
                return;
            }

            this.getSuggestions();
        });
    }

    getSuggestions() {
        this.retrieveSuggestions().then((response) => {
            if (typeof response.data === 'undefined' || typeof response.data.message === 'undefined') {
                return;
            }

            let href = this.createClickAbleSuggestion();
            href.innerText = response.data.message;
            href.addEventListener('click', (e) => {
                e.preventDefault();

                this.inputElm.value = href.innerText;
                this.container.classList.toggle('show');
            });

            this.container.innerHTML = trans('email_validation.suggestions') + ' ';
            this.container.append(href);
            this.container.classList.toggle('show');
        }).catch((error) => {
            // Catch errors but don't show them to user because
            // we don't want to interfere the process
            console.log(error);
        });
    }

    retrieveSuggestions() {
        const formData = new FormData();
        formData.append('email', this.inputElm.value);

        return post('/validate/email', formData);
    }
}

export default EmailValidation;
