var banner = new function () {
    this.showDelay = 1000;
};

banner.leftTop = function () {
    $('.spacer-left').show();
    $('#header .top-left').delay(banner.showDelay).fadeIn();
};

banner.leftMiddle = function () {
    $('.spacer-left').show();
    var middleheight = $('#header .middle-left').height() / 2;
    $('#header .middle-left').css({
        'marginTop': '-' + middleheight + 'px',
        'display': 'table'
    }).delay(banner.showDelay).animate({opacity: '1'}, 300);
};


banner.leftBottom = function () {
    $('.spacer-left').show();
    $('#header .bottom-left').delay(banner.showDelay).fadeIn();
};


banner.top = function () {
    $('.spacer-middle').show();
    $('#header .top').css('display', 'table').delay(banner.showDelay).animate({opacity: '1'}, 300);
};

banner.middle = function () {
    $('.spacer-middle').show();
    var middleheight = $('#header .middle').height() / 2;
    $('#header .middle').css({
        'marginTop': '-' + middleheight + 'px',
        'display': 'table'
    }).delay(banner.showDelay).animate({opacity: '1'}, 300);
};

banner.bottom = function () {
    $('.spacer-middle').show();
    $('#header .bottom').delay(banner.showDelay).fadeIn();
};

banner.rightTop = function () {
    $('.spacer-right').show();
    $('#header .top-right').delay(banner.showDelay).fadeIn();
};

banner.rightMiddle = function () {
    $('.spacer-right').show();
    var middleheight = $('#header .middle-right').height() / 2;
    $('#header .middle-right').css({'marginTop': '-' + middleheight + 'px'}).delay(banner.showDelay).fadeIn();
};

banner.rightBottom = function () {
    $('.spacer-right').show();
    $('#header .bottom-right').delay(banner.showDelay).fadeIn();
};

module.exports = banner;
