export default function hideMessages () {
  const containerMessages = document.querySelector('.messages')
  const dismissAlertMessage = document.querySelector('[data-dismiss="messages"]')
  if (containerMessages && dismissAlertMessage) {
    dismissAlertMessage.addEventListener('click', function () {
      const alertMessage = document.querySelector('.messages')
      alertMessage.classList.add('messages--hidden')
    })
  }
}
